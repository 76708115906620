<template>
	<div class="row m-0 p-0">
		<div
			style="height: 50px; position: relative; overflow: visible"
			class="shdow mr-2"
		>
			<p><i class="flag-icon flag-icon-it" /> Italie</p>
			<div style="position: absolute; top: -5px; right: -5px">
				<span style="cursor: pointer"
					><i class="mdi mdi-close-circle text-danger"></i
				></span>
			</div>
		</div>
		<div
			style="height: 50px; position: relative; overflow: visible"
			class="shdow mr-2"
		>
			<p><i class="flag-icon flag-icon-fr" /> France</p>
			<div style="position: absolute; top: -5px; right: -5px">
				<span style="cursor: pointer"
					><i class="mdi mdi-close-circle text-danger"></i
				></span>
			</div>
		</div>

		<div class="d-flex align-items-center ml-2">
			<a style="font-size: 25px" href="#" class="text-secondary" @click.prevent>
				<i class="mdi mdi-plus-circle-outline"></i
			></a>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style>
</style>