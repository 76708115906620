<template>
	<div>
		<b-card>
			<div class="row mb-4">
				<div class="col-12">
					<modal-actions @close="$router.go(-1)" />
				</div>
			</div>
			<div class="shdow">
				<div
					class="d-flex flex-wrap justify-content-between align-items-center"
				>
					<p class="h2 text-secondary">Modifier Application</p>
					<b-button v-if="editMode" variant="secondary">Confirmer</b-button>
					<hr class="w-100" />
				</div>

				<div class="row">
					<div class="col-12 col-md-6">
						<b-form-group
							label-cols="4"
							label-cols-lg="3"
							label="Libellé court"
						>
							<b-form-input type="text" value="Libellé 1"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="4" label-cols-lg="3" label="Statut">
							<b-form-select
								:options="['En prod', 'En cours', 'Terminé']"
								value="En prod"
							></b-form-select>
						</b-form-group>
						<b-form-group label-cols="4" label-cols-lg="3" label="Ligne métier">
							<b-form-select :options="['IARD']" value="IARD"></b-form-select>
						</b-form-group>
						<b-form-group label-cols="4" label-cols-lg="3" label="Type SI">
							<b-form-select
								:options="['SI Interne', 'Lorem', 'Ipsum']"
								value="SI Interne"
							></b-form-select>
						</b-form-group>
						<b-form-group label-cols="4" label-cols-lg="3" label="Patrimoine">
							<b-form-select
								:options="['Maiores', 'Patrimoine 1', 'Ipsum']"
								value="Patrimoine 1"
							></b-form-select>
						</b-form-group>
					</div>
					<div class="col-12 col-md-6">
						<b-form-group label-cols="4" label-cols-lg="3" label="Libellé long">
							<b-form-input type="text" value="Libellé long 1"></b-form-input>
						</b-form-group>
						<b-form-group
							label-cols="4"
							label-cols-lg="3"
							label="Type d'applications"
						>
							<b-form-select
								:options="['numquam', 'iste', 'dolor']"
								value="numquam"
							></b-form-select>
						</b-form-group>
						<b-form-group label-cols="4" label-cols-lg="3" label="Portée">
							<b-form-select
								:options="['Local', 'consectetur', 'aut']"
								value="Local"
							></b-form-select>
						</b-form-group>
						<b-form-group label-cols="4" label-cols-lg="3" label="Taux">
							<simple-slider />
						</b-form-group>
						<b-form-group label-cols="4" label-cols-lg="3" label="Couverture">
							<coverage />
						</b-form-group>
					</div>
				</div>
			</div>

			<div class="row mt-3">
				<div class="col-12">
					<b-tabs
						:value="$store.state.tabs.activeTab"
						@changed="activeTab = $store.state.tabs.activeTab"
						@activate-tab="
							(newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
						"
						class="tab-solid tab-solid-primary mt-3"
					>
						<b-tab title="Référents">
							<application-referent />
						</b-tab>
						<b-tab title="Bénéficiaires">
							<application-payee />
						</b-tab>
						<b-tab title="Capacité">
							<application-capacity />
						</b-tab>
						<b-tab title="Processus supportés">
							<application-process />
						</b-tab>
						<b-tab title="Fonctions">
							<application-function />
						</b-tab>
						<b-tab title="API Exposées">
							<application-api />
						</b-tab>
						<b-tab title="Données & GDPR">
							<application-data />
						</b-tab>

						<b-tab title="Composants Techniques">
							<application-technical-component />
						</b-tab>

						<b-tab title="Flux et échanges">
							<application-exchange />
						</b-tab>

						<b-tab title="Transformations">
							<application-transformation />
						</b-tab>
					</b-tabs>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import ApplicationReferent from "./components/ApplicationReferent.vue";
import ApplicationPayee from "./components/ApplicationPayee.vue";
import ApplicationCapacity from "./components/ApplicationCapacity.vue";
import ApplicationProcess from "./components/ApplicationProcess.vue";
import ApplicationFunction from "./components/ApplicationFunction.vue";
import ApplicationApi from "./components/ApplicationApi.vue";
import ApplicationData from "./components/ApplicationData.vue";
import ApplicationTechnicalComponent from "./components/ApplicationTechnicalComponent.vue";
import ApplicationExchange from "./components/ApplicationExchange.vue";
import ApplicationTransformation from "./components/ApplicationTransformation.vue";
import SimpleSlider from "@/components/forms/sliders/simpleSlider.vue";
import Coverage from "@/components/custom/Coverage.vue";

export default {
	components: {
		ModalActions,
		ApplicationReferent,
		ApplicationPayee,
		ApplicationCapacity,
		ApplicationProcess,
		ApplicationFunction,
		ApplicationApi,
		ApplicationData,
		ApplicationTechnicalComponent,
		ApplicationExchange,
		ApplicationTransformation,
		SimpleSlider,
		Coverage,
	},
	data: () => ({
		editMode: false,
	}),
	methods: {},
};
</script>

<style></style>
